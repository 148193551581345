import * as React from "react"
import "./style.css"

const PremiumButton = ({ children, onClick }) => {
  return (
    <div className="flex justify-center items-center w-full mt-5">
      <div
        className="rounded-full shadow-2xl cursor-pointer inline-block size-8 w-max"
        onClick={onClick}
        style={{ backgroundColor: "#2196f3", padding: "8px 16px" }}
      >
        <div className="text-center" style={{ color: "#ffffff" }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default PremiumButton
