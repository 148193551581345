import * as React from "react"
import ContentPageLayout from "../../../components/ContentPageLayout"
import { getLives } from "../../../api/content"
import { BiLeftArrowAlt } from "@react-icons/all-files/bi/BiLeftArrowAlt"
import { Link, navigate } from "gatsby"
import config, { firebaseInit } from "../../../config"
import AgoraRTC from "agora-rtc-sdk"
import AgoraRTM from "agora-rtm-sdk"
import AgoraVideoContainer from "../../../components/AgoraVideoContainer"
import AgoraChatContainer from "../../../components/chat/AgoraChatContainer"
import SCButton from "../../../components/buttons/SCButton"
import firebase from "firebase"
import PremiumButton from "../../../components/buttons/PremiumButton"

const AgoraRTCclientId = "af246c8f653542249d12d30d4b7c1c34" // PROD?
// const AgoraRTCclientId = '557807fefc9947c7a6a32cbd74335535'; // TEST
const AgoraRTMclientId = "c9deaaaf66aa461eba102db6881929cc" // TEST
const AgoraRTMToken =
  "00670a7ce0fe8c74a50b29040d20aa92652IAA4G7sGqjvKoC1mcyGfOqI03OXXcMN6mGczbOwobxEKEbfv3IMAAAAAEABM3CgvJo7EYgEA6AMmjsRi"
const testing = false
const testingToken =
  "006557807fefc9947c7a6a32cbd74335535IAAjBMOHLEVuoJhuJI+3A8SUPcHxCjrWiI2undWJMYb2ys3utV4AAAAAEAC7u3HEdoTHYgEAAQB1hMdi"

const LivePage = ({ ...props }) => {
  let liveId = props.params.id
  const [live, setLive] = React.useState()
  const [userHasSuscription, setUserHasSuscription] = React.useState(null)
  const [isContentPremium, setIsContentPremium] = React.useState(true)
  const [isAddVideoId, setIsAddVideoId] = React.useState(null)
  const [volume, setVolume] = React.useState(70)
  const [channel, setChannel] = React.useState()
  const [messages, setMessages] = React.useState([])
  const [user, setUser] = React.useState()
  const [userId, setUserId] = React.useState()
  const [timeUse, setTimeUse] = React.useState(0)
  const [runTime, setRunTime] = React.useState(false)
  const [userToken, setUserToken] = React.useState(null)

  const [fisrtGtag, setFirsrtGtag] = React.useState(false)

  let clientchat

  const profileUri = config.serverUri

  //Bring live data
  React.useEffect(() => {
    getLives(liveId).then(data => {
      setLive(data)
      setIsContentPremium(data.tipoContenido)
      if (!fisrtGtag) {
        const info = {
          nombreLive: data.nombre,
          idLive: data.id,
        }
        console.log(info)
        window.gtag("event", `live-${data.nombre}-Web`, info)
      }
    })
  }, [liveId])

  //Bring user suscription
  React.useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken(true).then(token => {
          setUserToken(token)
        })

        fetch(`${profileUri}/users/?email=${user.email}`)
          .then(response => {
            if (response.ok) {
              response.json().then(data => {
                if (data.length > 0) {
                  setUserId(data[0].id)

                  const connect = async () => {
                    clientchat = AgoraRTM.createInstance(AgoraRTMclientId)
                    await clientchat.login({
                      uid: String(data[0].id),
                      token: undefined,
                    })
                    const channel = clientchat.createChannel("main")

                    await channel.join()

                    channel.on("ChannelMessage", (message, memberId) => {
                      setMessages(currentMessages => [
                        ...currentMessages,
                        {
                          uid: memberId,
                          text: message.text,
                        },
                      ])
                    })

                    setChannel(channel)
                    return channel
                  }

                  const connection = connect()
                }
              })
            }
          })
          .catch(error => {
            console.log(error)
          })

        setUser(user)
        fetch(profileUri + `/users/?email=${user.email}`)
          .then(response => {
            response.json().then(data => {
              if (data.length > 0) {
                setUserHasSuscription(data[0].suscription)
              } else {
                setUserHasSuscription(false)
              }
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        setUserHasSuscription(false)
      }
    })
  }, [])

  //---AGORA STREAM---
  React.useEffect(() => {
    const handleError = err => {
      console.log("Error: ", err)
    }

    const addVideoStream = elementId => {
      setIsAddVideoId(elementId)
    }

    let client = AgoraRTC.createClient({
      mode: "live",
      codec: "vp8",
    })

    client.init(
      AgoraRTCclientId,
      () => {
        console.log("client initialized")
      },
      err => {
        console.log("client init failed ", err)
      }
    )

    let role = "audience"
    client.setClientRole(role)

    const joinChannel = token => {
      client.join(
        token,
        "sportscity",
        null,
        uid => {
          // Create a local stream
        },
        handleError
      )
    }

    if (testing) {
      joinChannel(testingToken)
    } else if (live?.token) {
      joinChannel(live.token)
    }

    client.on("stream-added", evt => {
      client.subscribe(evt.stream, handleError)
    })

    client.on("unmute-audio", () => {
      console.log("unmute")
    })

    client.on("stream-subscribed", evt => {
      let stream = evt.stream
      let streamId = String(stream.getId())
      addVideoStream(streamId)
      stream.play(streamId)
    })

    client.on("stream-removed", evt => {
      let stream = evt.stream
      stream.stop()
      stream.close()
      setIsAddVideoId(null)
    })

    client.on("peer-leave", evt => {
      let stream = evt.stream
      stream.stop()
      stream.close()
      setIsAddVideoId(null)
    })
  }, [live])

  const volumen = newValue => {
    setVolume(newValue)
  }

  const sendMessages = messages => {
    if (channel) {
      channel.sendMessage({ text: messages })
    }
  }

  React.useEffect(() => {
    let intervalId

    if (runTime && timeUse < 120) {
      intervalId = setInterval(() => {
        setTimeUse(prevTime => prevTime + 1)
      }, 1000)
    } else {
      clearInterval(intervalId)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [runTime])

  React.useEffect(() => {
    if (live !== undefined && timeUse === 120) {
      saveContentView()
      setRunTime(false)
    }
  }, [timeUse])

  const handlePlayLive = live => {
    const b2bTime = JSON.parse(localStorage.getItem("B2B_PLAY"))
    if (b2bTime === null) {
      const data = {
        start: Date.now(),
        content: live,
        token: userToken,
      }

      localStorage.setItem("B2B_PLAY", JSON.stringify(data))
    }
  }

  const saveContentView = () => {
    const data = {
      contenidoId: "" + live.id,
      contenido: "" + live.nombre,
    }

    createContentReproduced(userToken, data).then(res => {
      console.log(res)
    })
  }

  return (
    <ContentPageLayout>
      {live && (
        <div className="pb-20">
          <div>
            <div
              className="flex relative"
              style={{ height: 240, width: "100%" }}
            >
              <img
                className="absolute"
                src={`${profileUri}/uploads/banner_superior_8acc5551bb.png`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt={props.name}
              ></img>
              <div className="absolute flex p-5 h-full flex-col align-bottom">
                <h1 className="text-white ">Lives</h1>
                <p className="text-white">
                  Aquí podrás encontrar tu rutina ideal de acuerdo al grupo
                  muscular que quieras trabajar y la intensidad adecuada para tu
                  nivel.
                </p>
              </div>
            </div>
          </div>
          <div className="w-11/12 my-6 mx-8 flex items-center">
            <Link to={`/sections/entrenamiento`}>
              <BiLeftArrowAlt className="text-3xl" />
            </Link>
            <span className="pl-4">Entrenamiento / Planes / {live.nombre}</span>
          </div>
          <div className="w-10/12 mx-auto my-0">
            <div className="p-8">
              <h2 className="text-sc-titles">{live.nombre}</h2>
              <p>{live.objetivo}</p>
            </div>
            <div className="flex flex-col justify-center w-full md:flex-row md:p-8 md:justify-center">
              <>
                {(isContentPremium === "premium" &&
                  userHasSuscription === "premium") ||
                isContentPremium === "free" ? (
                  !isAddVideoId ? (
                    <>
                      <img
                        src={
                          `${profileUri}${live.portada?.url}` ||
                          "../../../images/entrenamiento.png"
                        }
                        style={{
                          minWidth: "300px",
                          height: "100%",
                          objectFit: "cover",
                          overflow: "hidden",
                        }}
                        alt={live.name}
                      ></img>
                      <div className="p-8">
                        <div className="mt-8">
                          <div className="p-8 flex items-center align-center justify-center">
                            <h4 className="text-center">
                              Por el momento el chat no está disponible
                            </h4>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <AgoraVideoContainer
                        vol={volume}
                        volumen={volumen}
                        isAddVideoId={isAddVideoId}
                      />
                      <div className="p-0 md:pl-4 flex items-center md:w-1/2 align-center justify-center">
                        <AgoraChatContainer
                          uid={userId}
                          channel={channel}
                          sendMessage={sendMessages}
                        />
                      </div>
                      {() => {
                        setRunTime(true)
                        handlePlayLive(live)
                      }}
                    </>
                  )
                ) : (
                  <div className="w-full flex flex-col items-center justify-center p-10">
                    <p className="text-3xl">
                      Se necesita ser usuario Shifter Premium para poder
                      visualizar este contenido
                    </p>
                    <div className="w-72 mt-8 mx-auto">
                      <PremiumButton
                        onClick={() => navigate("/sections/entrenamiento")}
                      >
                        Hazte premium
                      </PremiumButton>
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      )}
    </ContentPageLayout>
  )
}

export default LivePage
