import React from 'react'

const Emojis =["😄","👍","🤘","💪","👋","👊","❤️","💯","🔥","💦"]

const EmojisChatContainer = ({sendEmoji}) =>{

    const handleSendEmoji = (e)=>{
        sendEmoji(Emojis[e])
    }
    return(
        <div className="bg-sc-black opacity-90 rounded-xl flex flex-row justify-around " >
            {Emojis.map((emoji,index)=>(
                <div className="cursor-pointer hover:scale-150 transform" onClick={()=>handleSendEmoji(index)} key={index}>
                    {emoji}
                </div>
            ))}
        </div>
    )
}

export default EmojisChatContainer