import React from 'react'
import { getUserDetails } from '../../api/content'

const NameUserChat = ({userID}) =>{
    const [user, setUser] = React.useState(null)
    const [loading, setLoading] = React.useState(true)

    React.useEffect(()=>{
        getUserDetails(userID).then(data => {
            setUser(data)
            setLoading(false)
        }).catch(() => setLoading(false))
    }, [userID])

    const oneName = name =>{
        let oneName = String(name).split(" ")
        return oneName[0]
    }

    if (loading) {
        return <div className='text-xs pl-3 pt-0 font-black text-left text-sc-black'>Cargando...</div>
    }

    return(
        <div className='text-xs pl-3 pt-0 font-black text-left text-sc-black'>
            {user ? oneName(user.nombre) : 'Desconocido'}
        </div>
    )
}

export default NameUserChat
