import React from 'react'
import { getUserDetails } from '../../api/content'
import { StaticImage } from "gatsby-plugin-image"

const ImageUserChat = ({userID, color}) =>{
    const [user, setUser]= React.useState([])
    
    React.useEffect(()=>{
        getUserDetails(userID).then(data=>{
            setUser(data)
        })
    },[userID])

    return(
        <div className='flex flex-col'>
            <div className="h-8 rounded-full items-center w-8 " style={{borderBlockColor:color}}>
                {user.avatar? (
                    <img
                        className='inline-block object-cover  p-0.5 w-full h-full rounded-full'
                        src={user.avatar}
                        alt="profile image"
                    />
                    ):(
                <StaticImage
                    alt="user picture"
                    src="../../images/userprofile.png"
                    className="inline-block object-cover p-0.5 w-full h-full rounded-full"
                ></StaticImage>
                )}
            </div>
            
        </div>
        
    )
}

export default ImageUserChat