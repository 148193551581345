import React from "react"
import ImageUserChat from "./ImageUserChat"
import { IoMdSend } from "@react-icons/all-files/io/IoMdSend"
import { VscSmiley } from "@react-icons/all-files/vsc/VscSmiley"
import EmojisChatContainer from "./EmojisChatContainer"
import NameUserChat from "./NameUserChat"

const AgoraChatContainer = ({ channel, uid, sendMessages }) => {
  const messagesEndRef = React.useRef(null)
  const [text, setText] = React.useState("")
  const [messages, setMessages] = React.useState([])
  const [showEmojis, setShowEmojis] = React.useState(false)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const sendText = async e => {
    e.preventDefault()
    if (text === "") return
    await channel.sendMessage({ text })
    setMessages(currentMessages => [
      ...currentMessages,
      {
        uid,
        text,
      },
    ])
    setText("")
    scrollToBottom()
  }

  React.useEffect(() => {
    if (channel) {
      channel.on("ChannelMessage", (message, memberId) => {
        setMessages(currentMessages => [
          ...currentMessages,
          {
            uid: memberId,
            text: message.text,
          },
        ])
        scrollToBottom()
      })
    }
  }, [channel])

  const handleReactions = emoji => {
    setText(prevText => prevText + emoji)
  }

  return (
    <div className="chat w-full h-80 md:h-full relative">
      <div className="panel overflow-y-auto h-72 md:h-96 m-1 mb-8 md:mb-4 mt-0">
        <div className="messages m-1">
          {messages.map((message, index) => (
            <div key={index} className="message p-1 flex flex-row">
              <div className="items-center ml-3 mt-3 mb-3">
                <ImageUserChat
                  color={message.uid === uid ? "red" : "green"}
                  userID={message.uid}
                />
              </div>
              <div className="border-2 border-sc-primary rounded-lg w-full ml-2 m-1">
                <NameUserChat userID={message.uid} />
                <div className="text-xs text-sc-black m-2">{message.text}</div>
              </div>
            </div>
          ))}
        </div>
        <div ref={messagesEndRef} />
      </div>
      <div className="z-100 w-full mb-10 -mt-16 pl-2 pr-2 md:mb-10 md:-mt-8">
        {showEmojis && <EmojisChatContainer sendEmoji={handleReactions} />}
      </div>
      <form
        onSubmit={sendText}
        className="flex flex-row fixed absolute inset-x-0 bottom-0 m-1 z-0"
      >
        <div
          className="rounded-full p-1 mr-1"
          onClick={() => setShowEmojis(!showEmojis)}
        >
          <VscSmiley size={20} color="#00745F" />
        </div>
        <input
          className="rounded-lg mr-1 w-full text-white pl-1 bg-sc-body placeholder:text-white placeholder:ml-2"
          placeholder="Escribe tu comentario"
          value={text}
          onChange={e => setText(e.currentTarget.value)}
        />
        <button className="bg-white rounded-full p-1 ">
          <IoMdSend color="#00745F" />
        </button>
      </form>
    </div>
  )
}

export default AgoraChatContainer
